<template>
    <div :class="'panel-module-container ' + config.class_prop">
        <div v-for="(row ,index) in config.panels" :key="'panel-list-'+index" :class="'default-flex-content ' + row.rowClass">
            <div v-for="(panel, j) in row.panels" :style="panel.style.value" :class="panel.panelClass.value" :key="'comp'+j + '-' + index">
                <component
                    :is="panel.modname.value" 
                    :config="panel.config"
                    
                />
            </div>
        </div>
    </div>
</template>

<script>
    
    import modules from '@/store/module-import.js'

    export default {
        props: ["config"],
        components: {
            ...modules
            
        },
        mounted(){
            for(let i=0; i < this.config.panels.length; i++){
                for(let j=0; j < this.config.panels[i].panels.length; j++){
                    let panel = this.config.panels[i].panels[j];

                    if(!panel.panelClass){
                        panel.panelClass = {value: ""};
                    }
                }
            }
            
            this.$forceUpdate();
        }
    }
    
</script>

<style>

    .default-flex-content{
        display: flex;
    }


</style>
